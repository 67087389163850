import React from "react";
import { Container } from "react-bootstrap";
import NavbarOp from "../components/Navbar";
import img from '../img/Icone_botao_exemplo.jpeg';
import '../css/LeftImageContainer.css'

export default function Home(){

    const [user, setUser] = React.useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = React.useState(null);

    //console.log(user);
    //console.log("Empresa selecionada: ", empresaSelecionada);

    return(

        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            <Container>

                <br />

                <h5 style={{textAlign: "center"}}>Bem vindo ao e-Contador da Hostmundo</h5>
                <br />

                <p>Para baixar os documentos fiscais acesse no menu superior direito <img src={img} alt="Logo" style={{width: "45px", height: "35px"}}/>, clique nele, selecione umas das empresas que você tem acesso e depois no mesmo menu selecione qual tipo de documento fiscal você deseja baixar, depois, busque por periodo desejado e clique em Baixar XML.</p>

            </Container>

        </Container>

    );

}
