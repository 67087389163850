import React from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import axios from 'axios';
import global from "../global";
import * as Sentry from "@sentry/react";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import { useMask } from "@react-input/mask";

export default function Login() {

    const [nome, setNome] = React.useState("");
    const [wpp, setWpp] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [senha, setSenha] = React.useState("");
    const [senhaCad, setSenhaCad] = React.useState("");
    const [senhaCadConf, setSenhaCadConf] = React.useState("");
    const [codigoCadastrarSenha, setCodigoCadastrarSenha] = React.useState("");

    //controles das views
    const [viewEmail, setViewEmail] = React.useState(true);
    const [viewSenha, setViewSenha] = React.useState(false);
    const [viewCadastrarSenha, setViewCadastrarSenha] = React.useState(false);
    const [viewValidarCadastrarSenha, setViewValidarCadastrarSenha] = React.useState(false);
    const [viewEsqueceuSenha, setViewEsqueceuSenha] = React.useState(false);
    
    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");

    //Modal Load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //mascara 
    const mascaraTelefone = useMask({ mask: '+55 (__) _________', replacement: { _: /\d/ } });

    console.log("Email: ", email);
    console.log("Senha: ", senha);
    console.log("Senha Cad: ", senhaCad);
    console.log("Senha Cad Conf: ", senhaCadConf);

    return (

        <Container className='mx-auto border border-dark bg-white' style={{width: "75%", marginTop: "5%", marginBottom: "5%"}}>

            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
            />

            <ModalLoad
                showModal={showModalLoad}
            />

            <Container >
                {viewEmail 
                &&<Container>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Login e-Contador</h1></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Email: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text" 
                                    placeholder="User" 
                                    value={email} 
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setShowModalLoad(true);

                                    if(email == "" || email == null){
                                        
                                        setMsgModalErro("Preencha os campos corretamente!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }
                            
                                    axios.post(global.URL_BASE + global.PATH_VALIDAEMAIL, {
                                        
                                        email: email

                                    })
                                    .then(function (response) {

                                        console.log(response);

                                        let data = response.data;

                                        if(data.code == 0){

                                            if(data.content){

                                                setViewEmail(false);
                                                setViewSenha(true);
                                                setViewCadastrarSenha(false);
                                                setShowModalLoad(false);

                                            }else{

                                                //Enviar codigo de validação do email

                                                axios.post(global.URL_BASE + global.PATH_ENVIA_CODIGOEMAIL, {
                                                    
                                                    "email": email

                                                })
                                                .then(function (response) {
                                                    
                                                    if(response.data.code == 0){

                                                        console.log(response.data);

                                                        if(response.data.content == true){

                                                            setViewEmail(false);
                                                            setViewSenha(false);
                                                            setViewValidarCadastrarSenha(true);

                                                        }else{

                                                            setMsgModalErro(response.data.msg);
                                                            setShowModalErro(true);

                                                        }

                                                        setShowModalLoad(false);

                                                    }else{

                                                        setMsgModalErro(response.data.msg);
                                                        setShowModalErro(true);
                                                        setShowModalLoad(false);

                                                    }

                                                })
                                                .catch(function (error) {

                                                    Sentry.captureException(error);

                                                    setMsgModalErro("Erro ao enviar o email: " + error);
                                                    setShowModalErro(true);

                                                    setShowModalLoad(false);

                                                })
                                            }
                                            
                                        }else{

                                            setMsgModalErro(data.msg);
                                            setShowModalErro(true);

                                            setViewEmail(true);
                                            setViewSenha(false);

                                            setShowModalLoad(false);

                                        }
                                        
                                    })
                                    .catch(function (error) {

                                        Sentry.captureException(error);

                                        console.log(error);

                                        setMsgModalErro(error.message);
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                    })

                                }}
                                >Avançar</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>

                </Container>
                }

                {viewEsqueceuSenha
                &&<Container>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Login e-Contador</h1></Col>
                    </Row>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Sua Senha foi enviada para seu email!</h1></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setViewEmail(true);
                                    setViewEsqueceuSenha(false);

                                }}
                                >Voltar ao login</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>
                </Container>
                }

                {viewValidarCadastrarSenha
                &&<Container>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Validar codigo de acesso: </h1></Col>
                    </Row>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h4>Enviamos um email com um codigo para cadastrar sua senha </h4></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Codigo validação: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="password" 
                                    placeholder="User" 
                                    value={codigoCadastrarSenha} 
                                    onChange={(e) => {
                                        setCodigoCadastrarSenha(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setShowModalLoad(true);

                                    if(codigoCadastrarSenha == "" || codigoCadastrarSenha == null){
                                        
                                        setMsgModalErro("Preencha os campos corretamente!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }
                                    
                                    axios.post(global.URL_BASE + global.PATH_VALIDACODIGO, {
                                        
                                        "email": email,
                                        "codigo": codigoCadastrarSenha

                                    })
                                    .then(function (response) {

                                        if(response.data.code == 0 && response.data.content == true){

                                            setViewValidarCadastrarSenha(false);
                                            setViewCadastrarSenha(true);

                                        }else{

                                            setMsgModalErro("Codigo invalido!");
                                            setShowModalErro(true);

                                        }
                                        
                                    })
                                    .catch(function (error) {
                                        
                                        Sentry.captureException(error);

                                        setMsgModalErro("Ocorreu um erro ao validar o email: " + error);
                                        setShowModalErro(true);

                                        console.log(error);

                                    })
                                    .finally(() => setShowModalLoad(false));

                                }}
                                >Cadastrar Senha</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>

                </Container>
                }

                {viewCadastrarSenha
                &&<Container>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Cadastrar uma senha para o seu e-mail: </h1></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>

                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Nome: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    placeholder="Nome" 
                                    value={nome} 
                                    onChange={(e) => {
                                        setNome(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>

                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Whatsapp: "
                                className="mb-3"
                            >
                                <Form.Control 
                                ref={mascaraTelefone}
                                    placeholder="Whatsapp: " 
                                    value={wpp} 
                                    onChange={(e) => {
                                        setWpp(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>

                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Senha: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="password" 
                                    placeholder="User" 
                                    value={senhaCad} 
                                    onChange={(e) => {
                                        setSenhaCad(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>

                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Confirmar Senha: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="password" 
                                    placeholder="User" 
                                    value={senhaCadConf} 
                                    onChange={(e) => {
                                        setSenhaCadConf(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                        
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setShowModalLoad(true);

                                    if(senhaCad == "" || senhaCad == null || senhaCadConf == "" || senhaCadConf == null){
                                        
                                        setMsgModalErro("Preencha os campos corretamente!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }

                                    if(wpp == "" || wpp == null || wpp.length < 17){
                                        
                                        setMsgModalErro("O whatsapp deve ser preenchido!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }

                                    if(nome == "" || nome == null){
                                        
                                        setMsgModalErro("O nome deve ser preenchido!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }

                                    if(senhaCad != senhaCadConf){

                                        setMsgModalErro("As senhas nao conferem!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;

                                    }
                                    
                                    axios.post(global.URL_BASE + global.PATH_CADASTRAEMIALSENHA, {
                                        
                                        nomeContador: nome,
                                        wppContador: wpp,
                                        emailContador: email,
                                        senhaContador: senhaCad

                                    })
                                    .then(function (response) {

                                        console.log(response);

                                        let data = response.data;

                                        if(data.code == 0){

                                            console.log(data.content);

                                            if(data.content){

                                                window.location.href = "/login";

                                            }else{

                                                setMsgModalErro("Ocorreu algum problema ao cadastrar uma nova senha para o seu e-mail!");
                                                setShowModalErro(true);

                                            }

                                            

                                        }else{

                                            setMsgModalErro(data.msg);
                                            setShowModalErro(true);

                                            setViewEmail(false);
                                            setViewSenha(false);
                                            setViewCadastrarSenha(true);

                                        }
                                        
                                    })
                                    .catch(function (error) {

                                        Sentry.captureException(error);
                                        
                                        setMsgModalErro("Ocorreu algum problema ao cadastrar uma nova senha para o seu e-mail: " + error);
                                        setShowModalErro(true);
                                        console.log(error);

                                    })
                                    .finally(() => {
                                        setShowModalLoad(false);
                                    });

                                }}
                                >Cadastrar Senha</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>

                </Container>
                }

                {viewSenha
                &&<Container>
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Login e-Contador</h1></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Email: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text" 
                                    placeholder="User" 
                                    defaultValue={email}
                                    disabled
                                />
                            </FloatingLabel>

                        </Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        >
                        
                            <FloatingLabel
                                label="Senha: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="password" 
                                    placeholder="User" 
                                    value={senha} 
                                    onChange={(e) => {
                                        setSenha(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                        
                    </Row>
                    <Row style={{}}>
                        <Col>
                            <a onClick={() => {

                                //

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_RECUPERAR_SENHA, {
                                        
                                    email: email,

                                })
                                .then( (response) => {

                                    if(response.data.code == 0){
                                        setViewSenha(false);
                                        setViewEsqueceuSenha(true);
                                    }else{
                                        setMsgModalErro(response.data.msg);
                                        setShowModalErro(true);
                                    }

                                })
                                .catch((error) => {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu algum erro ao recuperar sua senha: " + error);
                                    setShowModalErro(true);

                                })
                                .finally(() => {setShowModalLoad(false);});

                                
                            }}
                            style={{color: "blue", cursor: "pointer"}}
                            >Esqueceu sua senha?</a>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    setShowModalLoad(true);

                                    if(email == "" || email == null || senha == "" || senha == null){
                                        
                                        setMsgModalErro("Preencha os campos corretamente!");
                                        setShowModalErro(true);
                                        setShowModalLoad(false);

                                        return;
                                    }
                                    
                                    axios.post(global.URL_BASE + global.PATH_LOGIN, {
                                        
                                        email: email,
                                        senha: senha

                                    })
                                    .then(function (response) {

                                        console.log(response);

                                        let data = response.data;

                                        if(data.code == 0){

                                            sessionStorage.setItem("contadorObj", JSON.stringify(data.content));

                                            sessionStorage.setItem("logado", true);

                                            window.location.href = "/";

                                        }else{

                                            setMsgModalErro(data.msg);
                                            setShowModalErro(true);

                                            setViewEmail(true);
                                            setViewSenha(false);
                                            setViewCadastrarSenha(false);

                                        }

                                    })
                                    .catch(function (error) {

                                        Sentry.captureException(error);

                                        setMsgModalErro("Ocorreu algum erro ao tentar fazer login: " + error);
                                        setShowModalErro(true);

                                        console.log(error);

                                    })
                                    .finally(function () {setShowModalLoad(false);});

                                }}
                                >Login</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>

                </Container>
                }

                
                <Row style={{marginTop: "5%"}}/>
            </Container>
            
        </Container>

    );

}