import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal
} from 'react-bootstrap';
import { FaRegSquareCheck } from "react-icons/fa6";
import PropTypes from 'prop-types';

ModalSucesso.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired

}

export default function ModalSucesso({showModal, setShowModal, msg}) {

    return(
        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) } }
        keyboard={false}
        id="modalSucess"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Container>
                        <Row>
                            <Col>
                                <FaRegSquareCheck  size={50}/>
                            </Col>
                            <Col>
                                <h2>Sucesso</h2> 
                            </Col>
                        </Row>
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{marginBottom: "5%"}}>
                    <Col style={{textAlign: "center", alignItems: "center"}}><h3>{msg}</h3></Col>
                </Row>
                <Row >

                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                            variant="success"
                            onClick={ () => {setShowModal(false)} }
                            >OK</Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
    

}
