import React, { useEffect } from 'react';
import { 
    Button, 
    Container, 
    Navbar,
    Offcanvas,
    Nav
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Sentry from "@sentry/react";
import ModalSelecionarEmpresa from './Modal/ModalSelecionarEmpresa';

NavbarOp.propTypes = {

    user: PropTypes.object,
    setUser: PropTypes.func.isRequired,
    setEmpresaSelecionada: PropTypes.func.isRequired,
    empresaSelecionada: PropTypes.object

}

export default function NavbarOp({user, setUser, empresaSelecionada, setEmpresaSelecionada}) {

    const [showModalSelecionarEmpresa, setShowModalSelecionarEmpresa] = React.useState(false);

    //user
    useEffect(() => {

        let userConver = null;
        try {

            console.log("ContadorObj",sessionStorage.getItem("contadorObj"));

            if(user == null){

                if(sessionStorage.getItem("contadorObj") !== undefined){

                    userConver = JSON.parse(sessionStorage.getItem("contadorObj"));
    
                    //console.log("userConver: ", userConver);
        
                    if(userConver != null && userConver != undefined ){
                        
                        setUser(userConver);
                        //sessionStorage.removeItem("contadorObj");
        
                    }else{
        
                        if(sessionStorage.getItem("logado") == null || sessionStorage.getItem("logado") == undefined){
                            window.location.href = "/login";
                        }else{
                            sessionStorage.removeItem("logado");
                        }
        
                    }
    
                }

            }

        } catch (error) {

            Sentry.captureException(error);

            window.location.href = "/login";

        }

    },[user, setUser]);

    //empresa Selecionada
    useEffect(() => {

        let empresaSelecionadaConver = null;
        try {

            if(empresaSelecionada == null ){
                
                console.log("empresaSelecionadaObj", sessionStorage.getItem("empresaSelecionadaObj"));

                if(sessionStorage.getItem("empresaSelecionadaObj") != undefined){
    
                    empresaSelecionadaConver = JSON.parse( sessionStorage.getItem("empresaSelecionadaObj") );
    
                    console.log("empresaSelecionadaConver: ", empresaSelecionadaConver);
        
                    if(empresaSelecionadaConver != null && empresaSelecionadaConver != undefined ){
    
                        console.log("teste", empresaSelecionadaConver);
                        
                        setEmpresaSelecionada(empresaSelecionadaConver);
                        //sessionStorage.removeItem("empresaSelecionadaObj");
        
                        
        
                    }else{
    
                        console.log("teste2")
        
                        setShowModalSelecionarEmpresa(true); 
        
                    }
    
                }else{
    
                    setShowModalSelecionarEmpresa(true);
    
                }

            }

        } catch (error) {

            Sentry.captureException(error);

            console.log(error);
            setShowModalSelecionarEmpresa(true);

        }

    },[empresaSelecionada, setEmpresaSelecionada]);

    console.log("Empresa selecionada: ", empresaSelecionada);

    return(

        <div>

            <ModalSelecionarEmpresa 
            showModal={showModalSelecionarEmpresa} 
            setShowModal={setShowModalSelecionarEmpresa} 
            setEmpresaSelecionada={setEmpresaSelecionada} 
            user={user} />

            <Navbar 
            expand={false} 
            className='rounded'
            style={{width: "100%", backgroundColor: "#dddddd"}}
            
            >
                <Container fluid>
                    <Navbar.Brand>
                        <h2 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>Olá, {user != null && user != undefined ? user.nome : null} ! </h2>
                        <h5 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>Você está consultando a empresa: {empresaSelecionada != null && empresaSelecionada != undefined ? empresaSelecionada.nomeFantasia : null}</h5>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${false}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                    placement="end"
                    >

                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h2>e-Contador ERP</h2>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr />
                        <Offcanvas.Body>

                        <Nav.Item
                            
                            style={{


                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        sessionStorage.setItem("logado", true);
                                        sessionStorage.setItem("contadorObj", JSON.stringify(user));
                                        sessionStorage.setItem("empresaSelecionadaObj", JSON.stringify(empresaSelecionada));

                                        window.location.href = "/";

                                    }}
                                    
                                    ><h5>Home</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <hr />

                            <Nav.Item
                            
                            style={{

                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        sessionStorage.setItem("logado", true);
                                        sessionStorage.setItem("contadorObj", JSON.stringify(user));
                                        sessionStorage.setItem("empresaSelecionadaObj", JSON.stringify(empresaSelecionada));

                                        console.log(empresaSelecionada);

                                        window.location.href = "/listacfe";

                                    }}
                                    
                                    ><h5>CFE</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        sessionStorage.setItem("logado", true);
                                        sessionStorage.setItem("contadorObj", JSON.stringify(user));
                                        sessionStorage.setItem("empresaSelecionadaObj", JSON.stringify(empresaSelecionada));

                                        console.log(empresaSelecionada);

                                        window.location.href = "/listanfe";

                                    }}
                                    
                                    ><h5>NFe</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        sessionStorage.setItem("logado", true);
                                        sessionStorage.setItem("contadorObj", JSON.stringify(user));
                                        sessionStorage.setItem("empresaSelecionadaObj", JSON.stringify(empresaSelecionada));

                                        console.log(empresaSelecionada);

                                        window.location.href = "/listanfce";

                                    }}
                                    
                                    ><h5>NFC-e</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <hr />

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        setShowModalSelecionarEmpresa(true);

                                    }}
                                    
                                    ><h5>Trocar Empresa</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <Nav.Item

                            style={{
                                marginBottom: "1%",
                            }}

                            >
                                
                                <div className="d-grid gap-2">
                                    <Button onClick={
                                        () => {

                                            sessionStorage.removeItem("logado");
                                            sessionStorage.removeItem("contadorObj");
                                            sessionStorage.removeItem("empresaSelecionadaObj");

                                            window.location.href = "/login";

                                        }}
                                        variant="danger"
                                    >
                                        <h5>Sair</h5>
                                    </Button>
                                </div>

                            </Nav.Item>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

        </div>

        

    );

}