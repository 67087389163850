import { 
    Modal,
    Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';

ModalLoad.propTypes = {

    showModal: PropTypes.bool.isRequired

}

export default function ModalLoad({showModal}){

    return (

        <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        id="modalLoad"
        centered
        >
            <Modal.Header>
                <Modal.Title> Carregando...</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{justifyContent: "center", justifyItems: "center"}}>
                <Spinner animation="border" role="status" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Modal.Body>
        </Modal>

    );

}