import { Container, Row, Col } from "react-bootstrap";
import { BiError } from 'react-icons/bi';

export default function NotFound() {

    return (

        <Container className='mx-auto border border-dark bg-white' style={{width: "75%", marginTop: "5%", marginBottom: "5%"}}>

            <Row style={{textAlign: "center", marginTop: "5%"}}>
                <Col><h1><BiError /> Pagina não encontrada<BiError /> </h1></Col>
            </Row>
            <Row style={{marginTop: "5%"}}/>
            
        </Container>

    );

}